h1 {
   font-weight: 800 !important;
   font-size: 60px !important;
   line-height: 84px !important;
   @media (max-width: 1279px) {
      font-size: 40px !important;
      line-height: 49px !important;
   }
   @media (max-width: 767px) {
      font-size: 28px !important;
      line-height: 34px !important;
   }
}

h2 {
   font-weight: 800 !important;
   font-size: 40px !important;
   line-height: 49px !important;
   @media (max-width: 767px) {
      font-size: 24px !important;
      line-height: 29px !important;
   }
}

h3 {
   font-weight: 800 !important;
   font-size: 32px !important;
}

h4 {
   font-weight: 800 !important;
   font-size: 28px !important;
   line-height: 42px !important;
   @media (max-width: 767px) {
      font-size: 18px !important;
      line-height: 22px !important;
   }
}

h5 {
   font-size: 24px !important;
   font-weight: 800 !important;
   line-height: 28px !important;
}

h6 {
   font-size: 18px !important;
   font-weight: 800 !important;
   line-height: 22px !important;
}

.gmnoprint {
   margin-right: 32px !important;
   margin-bottom: 10px !important;
   @media (max-width: 767px) {
      margin-right: 24px !important;
      margin-bottom: 0 !important;
   }
   & > div > div:not(:first-child),
   &.gm-style-cc {
      display: none !important;
   }
}
.gm-control-active {
   width: 30px !important;
   height: 30px !important;
   left: 12px !important;
   line-height: 20px !important;
   background: #387dff !important;
   box-shadow: 0px 0px 12px #0052e01a !important;
   border-radius: 40px;
   img {
      display: none !important;
   }
}
.gm-control-active {
   background: #76ca3c !important;
   box-shadow: 0px 2px 4px #00000029 !important;
   border-radius: 4px !important;
   color: white !important;
   width: 32px !important;
   height: 32px !important;
   margin-bottom: 16px !important;
   @media (max-width: 767px) {
      margin-bottom: 8px !important;
   }
}
.gm-control-active:first-child:before {
   content: "+" !important;
   color: white !important;
   font-size: 36px !important;
   font-weight: bold !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}
.gm-control-active:nth-of-type(2):before {
   content: "−" !important;
   color: white !important;
   font-size: 36px !important;
   font-weight: bold !important;
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
}
.gmnoprint {
   div {
      background: none !important;
      box-shadow: none !important;
   }
}
