*,
*:before,
*:after {
   box-sizing: border-box;
   font-family: "Proxima Nova", sans-serif;
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   font-family: "Proxima Nova", sans-serif;
   vertical-align: baseline;
   text-rendering: optimizeLegibility;
   -webkit-font-smoothing: antialiased;
   text-size-adjust: none;
   outline: none;
}

footer,
header,
nav,
section,
main {
   display: block;
}

body {
   line-height: 1;
}

a {
   text-decoration: none;
}

ol,
ul {
   list-style-position: inside;
   list-style-type: none;
   margin-block-start: 0;
   margin-block-end: 0;
   margin-inline-end: 0;
   padding-inline-start: 0;
}

blockquote,
q {
   quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
   content: "";
   content: none;
}

table {
   border-collapse: collapse;
   border-spacing: 0;
}

input {
   -webkit-appearance: none;
   border-radius: 0;
}

button {
   outline: none;
   user-select: none;
   cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

/* Firefox */
input[type=number] {
   -moz-appearance: textfield;
}



